<template>
  <div id="app">
    <!-- header -->
    <header class="header">
      <div class="header__content">
        <div class="header__logo">
          <router-link to="/">
            <img src="/img/logo.svg" alt="AI Teading Logo">
          </router-link>
        </div>

        <div class="header__actions">
          <div class="header__action header__action--signin">
            <a class="header__action-btn d-none d-md-block" style='height: 100%' href="https://codingteading.com/">
              <span>코딩티딩 홈</span>
            </a>
          </div>
        </div>

        <button class="header__btn" type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
    <!-- end header -->

    <!-- sidebar -->
    <div class="sidebar">
      <!-- sidebar logo -->
      <div class="sidebar__logo">
        <router-link to="/">
          <img src="/img/logo.svg" alt="AI Teading Logo">
        </router-link>
      </div>
      <!-- end sidebar logo -->

      <!-- sidebar nav -->
      <ul class="sidebar__nav">
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'all'}" @click="changeSearching('all')">
            <i class="fas fa-th-list"></i>
            <span>전체</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'practice'}" @click="changeSearching('practice')">
            <i class="fas fa-book-reader"></i>
              <span>실습</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'learning'}" @click="changeSearching('learning')">
            <i class="fas fa-chalkboard-teacher"></i>
            <span>학습</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'picture'}" @click="changeSearching('picture')">
            <i class="fas fa-image"></i>
            <span>그림</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'music'}" @click="changeSearching('music')">
            <i class="fas fa-music"></i>
            <span>음악</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'recognition'}" @click="changeSearching('recognition')">
            <i class="fas fa-glasses"></i>
            <span>인식</span>
          </a>
        </li>
        <li class="sidebar__nav-item" style='margin-bottom: 9px;'>
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'tensorflow'}" @click="changeSearching('tensorflow')">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48" style='margin-left: -2px'>
              <polygon points="16,39.609 23,43.609 23,4 16,8"></polygon><polygon points="23,12.433 6,22.25 6,13.75 23,3.933"></polygon><polygon points="32,39.609 25,43.609 25,4 32,8"></polygon><polygon points="25,12.433 42,22.25 42,13.75 25,3.933"></polygon><polygon points="29,19.732 29,27.365 36,31.407 36,23.775"></polygon>
            </svg>
             <span>텐서플로우</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'dqn'}" @click="changeSearching('dqn')">
            <b style='font-size: 75%; margin-right: 8px'>DQN</b>
            <span>DQN</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'cnn'}" @click="changeSearching('cnn')">
            <b style='font-size: 75%; margin-right: 8px'>CNN</b>
            <span>CNN</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'rnn'}" @click="changeSearching('rnn')">
            <b style='font-size: 75%; margin-right: 8px'>RNN</b>
            <span>RNN</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'gan'}" @click="changeSearching('gan')">
            <b style='font-size: 75%; margin-right: 8px'>GAN</b>
            <span>GAN</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'driving'}" @click="changeSearching('driving')">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"/>
            </svg>
            <span>자율주행</span>
          </a>
        </li>
        <li class="sidebar__nav-item" style='margin-bottom: 9px;'>
          <a class='sidebar__nav-link' :class="{'sidebar__nav-link--active': sidebar === 'generative'}" @click="changeSearching('generative')">
            <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 66 66' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''><g><path d='M53.4 1c-6.1 0-11.1 5-11.1 11.1s5 11.1 11.1 11.1 11.1-5 11.1-11.1S59.5 1 53.4 1zm4.5 12-5.7 3.1c-.7.4-1.6-.1-1.6-.9V9c0-.8.9-1.3 1.6-.9l5.7 3.1c.7.4.7 1.4 0 1.8zM1.5 3.9V25c0 1.6 1.3 2.9 2.9 2.9h15.3c1.6 0 2.9-1.3 2.9-2.9V3.9c0-1.6-1.3-2.9-2.9-2.9H4.4C2.8 1 1.5 2.3 1.5 3.9zm5.7 1.9h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zm0 5.1h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zm0 5.1h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.5.5-1 1-1zm0 5.2h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zM27.1 14.4c2.7 0 5 2.2 5 5 0 .5.4 1 1 1 .5 0 1-.4 1-1 0-2.7 2.2-5 5-5 .5 0 1-.4 1-1 0-.5-.4-1-1-1-2.5 0-4.7 1.4-5.9 3.4-1.2-2-3.4-3.4-5.9-3.4-.5 0-1 .4-1 1-.2.6.3 1 .8 1zM56.9 30.6c-.3-.2-.7-.3-1-.1s-.5.5-.5.9v8.5c-.6-.3-1.2-.5-1.9-.5-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8V33.3l3.8 2.9c.4.3 1 .2 1.3-.2s.2-1-.2-1.3zM36.7 49.2V47c-2.5 1.3-4.9 1.3-7.4 0v2.2c0 2 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7zM33 22.9c-5.2 0-9.4 4.6-9.4 10.2 0 .5 0 1 .1 1.6 3.7-.4 7.1-3 8.4-6.5.3-.8 1.3-.9 1.8-.3 2 2.5 5.1 4 8.4 3.9-.6-5-4.5-8.9-9.3-8.9z' data-original='#000000' class=''></path><path
              d='M24.1 36.7c1.2 4.2 4.6 9.1 8.9 9.1 4.8 0 9-6.4 9.4-11.9-3.3.1-6.6-1.1-9-3.5-1.9 3.5-5.3 5.8-9.3 6.3zM38.6 50.5c-.6 2.5-2.9 4.4-5.6 4.4s-5-1.9-5.6-4.4l-7.8 2.1c-3 1-5.2 3.4-5.5 6-.2 1.3-.1 4.7 0 6.4h37.7c.1-1.7.1-5 0-6.3-.3-2.6-2.5-5-5.5-6z'
              data-original='#000000' class=''></path></g></svg>
            <span>생성형</span>
          </a>
        </li>
        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'noCamera'}" @click="changeSearching('noCamera')">
            <i class="fas fa-eye-slash"></i>
            <span>웹캠 없음</span>
          </a>
        </li>

<!--        <li class="sidebar__nav-item">
          <a href="artists.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"/></svg> <span>Artists</span></a>
        </li>

        <li class="sidebar__nav-item">
          <a href="releases.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z"/></svg> <span>Releases</span></a>
        </li>

        <li class="sidebar__nav-item">
          <a href="events.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg> <span>Events</span></a>
        </li>

        <li class="sidebar__nav-item">
          <a href="podcasts.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,15a4,4,0,0,0,4-4V5A4,4,0,0,0,8,5v6A4,4,0,0,0,12,15ZM10,5a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0Zm10,6a1,1,0,0,0-2,0A6,6,0,0,1,6,11a1,1,0,0,0-2,0,8,8,0,0,0,7,7.93V21H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2H13V18.93A8,8,0,0,0,20,11Z"/></svg> <span>Podcasts</span></a>
        </li>-->

        <!-- collapse -->
<!--        <li class="sidebar__nav-item">
          <a class="sidebar__nav-link" data-toggle="collapse" href="#collapseMenu1" role="button" aria-expanded="false" aria-controls="collapseMenu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,5.5H12.72l-.32-1a3,3,0,0,0-2.84-2H5a3,3,0,0,0-3,3v13a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8.5A3,3,0,0,0,19,5.5Zm1,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5.5a1,1,0,0,1,1-1H9.56a1,1,0,0,1,.95.68l.54,1.64A1,1,0,0,0,12,7.5h7a1,1,0,0,1,1,1Z"/></svg> <span>Pages</span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg></a>

          <div class="collapse" id="collapseMenu1">
            <ul class="sidebar__menu sidebar__menu&#45;&#45;scroll">
              <li><a href="artist.html">Artist</a></li>
              <li><a href="event.html">Event</a></li>
              <li><a href="release.html">Release</a></li>
              <li><a href="product.html">Product</a></li>
              <li><a href="article.html">Article</a></li>
              <li><a href="cart.html">Cart</a></li>
              <li><a href="profile.html">Profile</a></li>
              <li><a href="about.html">About</a></li>
              <li><a href="contacts.html">Contacts</a></li>
              <li><a href="pricing.html">Pricing plans</a></li>
              <li><a href="privacy.html">Privacy policy</a></li>
              <li><a href="signin.html">Sign in</a></li>
              <li><a href="signup.html">Sign up</a></li>
              <li><a href="forgot.html">Forgot password</a></li>
              <li><a href="404.html">404 Page</a></li>
            </ul>
          </div>
        </li>
        &lt;!&ndash; end collapse &ndash;&gt;

        <li class="sidebar__nav-item">
          <a href="store.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.5,19A1.5,1.5,0,1,0,10,20.5,1.5,1.5,0,0,0,8.5,19ZM19,16H7a1,1,0,0,1,0-2h8.49121A3.0132,3.0132,0,0,0,18.376,11.82422L19.96143,6.2749A1.00009,1.00009,0,0,0,19,5H6.73907A3.00666,3.00666,0,0,0,3.92139,3H3A1,1,0,0,0,3,5h.92139a1.00459,1.00459,0,0,1,.96142.7251l.15552.54474.00024.00506L6.6792,12.01709A3.00006,3.00006,0,0,0,7,18H19a1,1,0,0,0,0-2ZM17.67432,7l-1.2212,4.27441A1.00458,1.00458,0,0,1,15.49121,12H8.75439l-.25494-.89221L7.32642,7ZM16.5,19A1.5,1.5,0,1,0,18,20.5,1.5,1.5,0,0,0,16.5,19Z"/></svg> <span>Store</span></a>
        </li>

        <li class="sidebar__nav-item">
          <a href="news.html" class="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16,14H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm0-4H10a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-6H17V3a1,1,0,0,0-2,0V4H13V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H4A1,1,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5A1,1,0,0,0,20,4ZM19,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V6H7V7A1,1,0,0,0,9,7V6h2V7a1,1,0,0,0,2,0V6h2V7a1,1,0,0,0,2,0V6h2Z"/></svg> <span>News</span></a>
        </li>-->
      </ul>
      <!-- end sidebar nav -->
    </div>
    <!-- end sidebar -->

    <main class="main">
      <router-view @change-sidebar="changeSideBar" v-slot="{Component}">
        <component ref="view" :is="Component" />
      </router-view>
    </main>

    <!-- footer -->
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-4 order-md-1 order-lg-4 order-xl-1">
            <div class="footer__logo">
              <router-link to="/">
                <img src="/img/logo.svg" alt="AI Teading Logo">
              </router-link>
            </div>
            <p class="footer__tagline">인공지능을 쉽게 체험해 볼 수 있는 사이트입니다.<br/>A.I.의 다양한 기능을 확인해보세요.</p>
            <div class="footer__links">
              <a href="mailto:codingteading@gmail.com"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg> codingteading@gmail.com</a>
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
            <h6 class="footer__title">도움</h6>
            <div class="footer__nav">
              <router-link to="/about">소개</router-link>
              <router-link to="/license">라이선스</router-link>
              <router-link to="/privacy">개인정보보호</router-link>
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
            <h6 class="footer__title">카테고리</h6>
            <div class="footer__nav">
              <a class="footer_anchor" @click="changeSearching('all')">전체</a>
              <a class="footer_anchor" @click="changeSearching('practice')">실습</a>
              <a class="footer_anchor" @click="changeSearching('learning')">학습</a>
              <a class="footer_anchor" @click="changeSearching('picture')">그림</a>
              <a class="footer_anchor" @click="changeSearching('music')">음악</a>
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
            <h6 class="footer__title" style='visibility: hidden'>BLANK</h6>
            <div class="footer__nav">
              <a class="footer_anchor" @click="changeSearching('recognition')">인식</a>
              <a class="footer_anchor" @click="changeSearching('tensorflow')">텐서플로우</a>
              <a class="footer_anchor" @click="changeSearching('dqn')">DQN</a>
              <a class="footer_anchor" @click="changeSearching('cnn')">CNN</a>
              <a class="footer_anchor" @click="changeSearching('rnn')">RNN</a>
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-lg-3 order-md-4 order-xl-4">
            <h6 class="footer__title" style='visibility: hidden'>BLANK</h6>
            <div class="footer__nav">
              <a class="footer_anchor" @click="changeSearching('gan')">GAN</a>
              <a class="footer_anchor" @click="changeSearching('driving')">자율주행</a>
              <a class="footer_anchor" @click="changeSearching('generative')">생성형</a>
              <a class="footer_anchor" @click="changeSearching('noCamera')">웹캠 없음</a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="footer__content">
              <div class="footer__social">
                <a href="mailto:codingteading@gmail.com" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="Layer 2"><g data-name="01.mail"><circle cx="256" cy="256" r="256" fill="#2196f3" data-original="#2196f3" class=""></circle><g fill="#fff"><path d="M255.94 268.64 121 201.32v133.76a25.66 25.66 0 0 0 25.59 25.59h218.82A25.66 25.66 0 0 0 391 335.08V202.73z" fill="#ffffff" data-original="#ffffff" class=""></path><path d="M256.06 243.36 391 177.5v-.58a25.66 25.66 0 0 0-25.59-25.59H146.59A25.66 25.66 0 0 0 121 176z" fill="#ffffff" data-original="#ffffff" class=""></path></g></g></g></g></svg>
                </a>
                 <a href="https://codingteading.com/" target="_blank">
                   <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m256.251.5c141.383 0 256 114.616 256 256s-114.616 256-256 256-256-114.622-256-256 114.616-256 256-256zm-110.577 247.842v150.715h63.5v-90.6a6.968 6.968 0 0 1 6.967-6.963h80.215a6.964 6.964 0 0 1 6.962 6.963v90.6h63.512v-150.715l-110.582-80.337zm-29.4 4.154 135.769-98.64a6.974 6.974 0 0 1 8.29-.089l135.887 98.733 17.53-24.123-157.5-114.429-157.499 114.425z" fill="#4caf50" fill-rule="evenodd"/></svg>
                 </a>
                 <a href="https://www.youtube.com/channel/UCDZR1BZbnn66zmkx11KqfLQ" target="_blank"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#FF0000"/><path d="M22.6656 11.2958C22.4816 10.5889 21.9395 10.0322 21.251 9.84333C20.0034 9.5 15 9.5 15 9.5C15 9.5 9.99664 9.5 8.74891 9.84333C8.06045 10.0322 7.51827 10.5889 7.33427 11.2958C7 12.5769 7 15.25 7 15.25C7 15.25 7 17.923 7.33427 19.2042C7.51827 19.9111 8.06045 20.4678 8.74891 20.6568C9.99664 21 15 21 15 21C15 21 20.0034 21 21.251 20.6568C21.9395 20.4678 22.4816 19.9111 22.6656 19.2042C23 17.923 23 15.25 23 15.25C23 15.25 23 12.5769 22.6656 11.2958" fill="white"/><path d="M13.5 18V13L17.5 15.5001L13.5 18Z" fill="#FF0000"/></svg></a>
              </div>
              <small class="footer__copyright">© 2023 CodingTeading All rights reserved.</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end footer -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      sidebar: 'all',
      sidebarCodeToName: {
        all: '', practice: '실습', learning: '학습', picture: '그림', music: '음악', recognition: '인식', tensorflow: '텐서플로우', dqn: 'DQN', cnn: 'CNN', rnn: 'RNN', gan: 'GAN', driving: '자율주행', generative: '생성형', noCamera: '웹캠 없음'
      },
      sidebarNameToCode: {
        '': 'all', '실습': 'practice', '학습': 'learning', '그림': 'picture', '음악': 'music', '인식': 'recognition', '텐서플로우': 'tensorflow', 'DQN': 'dqn', 'CNN': 'cnn', 'RNN': 'rnn', 'GAN': 'gan', '자율주행': 'driving', '생성형': 'generative', '웹캠 없음': 'noCamera'
      }
    }
  },
  methods: {
    changeSideBar(name) {
      this.sidebar = this.sidebarNameToCode[name];
    },
    changeSearching(sidebar){
      this.sidebar = sidebar;
      if(this.$route.name === 'Home'){
        this.$refs.view.changeSearchingFromParent(this.sidebarCodeToName[sidebar]);
      }else{
        this.$router.push('/');
        setTimeout(() => {
          this.$refs.view.changeSearchingFromParent(this.sidebarCodeToName[sidebar]);
        }, 500);
      }

      setTimeout(() => {
        $('html, body').animate({
          scrollTop: $("#module-list").length === 0 ? 0 : $("#module-list").offset().top
        }, 250);
      }, 250);
    }
  }
}
</script>

<style>
</style>
